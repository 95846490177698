import React, { useEffect, useState } from "react";
import Loading from "../../../../../components/general/Loading";
import ServiceHourApis from "../../../../../apis/provider/serviceHourApis";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function ServiceHour({ serviceHours }) {
  const { t } = useTranslation();
  return (
    <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer">
      <Typography
        variant="h4"
        className="clientAdPorfile__mainContainer__adDetails__informationContainer__heading"
        sx={{ width: "200px" }}
      >
        {t("client.adsProfile.myServiceHour")}
      </Typography>
      <Grid className="clientAdPorfile__mainContainer__adDetails__informationContainer__table">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="customized scrollable table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: "bold" }}>
                  {t("client.adsProfile.day")}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                  {t("client.adsProfile.from")}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                  {t("client.adsProfile.to")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceHours.map((serviceHour) => (
                <StyledTableRow key={serviceHour.id}>
                  <StyledTableCell component="th" scope="row">
                    <Typography className="rate__tablecontainer__table__section__container__title">
                      {serviceHour.service_type == "holiday" ?
                        `${dayjs(serviceHour.holiday).format("d MMM, YYYY")}`
                      : serviceHour.day}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {serviceHour.from_time}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {serviceHour.to_time}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
