import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Rate({ services, rates }) {
  const { t } = useTranslation();

  const getServiceNameById = (id) => {
    return services.find((service) => service.id == id)?.name;
  };

  return (
    <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer">
      <Typography
        variant="h4"
        className="clientAdPorfile__mainContainer__adDetails__informationContainer__heading"
        sx={{ width: "120px" }}
      >
        {t("client.adsProfile.myRates")}
      </Typography>
      <Grid className="clientAdPorfile__mainContainer__adDetails__informationContainer__table">
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="customized scrollable table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ fontWeight: "bold" }}>
                  {t("providers.myRate.service")}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                  {t("providers.myRate.rateType")}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                  {t("providers.myRate.time")}
                </StyledTableCell>
                <StyledTableCell align="right" sx={{ fontWeight: "bold" }}>
                  {t("providers.myRate.price")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rates.map((rate) => (
                <StyledTableRow key={rate.id}>
                  <StyledTableCell component="th" scope="row">
                    <Box>
                      <Typography className="rate__tablecontainer__table__section__container__title">
                        {" "}
                        {getServiceNameById(rate.service_id)}
                      </Typography>
                      <Typography className="rate__tablecontainer__table__section__container__container__value">
                        {rate.description}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {rate.rate_type}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {rate.time_duration}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    $ {rate.price}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
}
