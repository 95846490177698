import { createSlice } from "@reduxjs/toolkit";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import TheatersOutlinedIcon from "@mui/icons-material/TheatersOutlined";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";

export const sidebarItems = [
  {
    id: "profile",
    path: "/provider/profile",
    icon: <PersonOutlineOutlinedIcon />,
    name: "navigation.myProfile",
    active: true,
  },

  {
    id: "photos",
    path: "/provider/photos",
    icon: <CameraAltOutlinedIcon />,
    name: "navigation.myPhoto",
    active: false,
  },
  {
    id: "videos",
    path: "/provider/videos",
    icon: <OndemandVideoOutlinedIcon />,
    name: "navigation.myVideo",
    active: false,
  },
  {
    id: "rates",
    path: "/provider/rates",
    icon: <AttachMoneyOutlinedIcon />,
    name: "navigation.myRate",
    active: false,
  },
  {
    id: "serviceHours",
    path: "/provider/serviceHours",
    icon: <AccessTimeOutlinedIcon />,
    name: "navigation.serviceHour",
    active: false,
  },
  {
    id: "ads",
    path: "/provider/ads",
    icon: <TheatersOutlinedIcon />,
    name: "navigation.myAd",
    active: false,
  },
  {
    id: "travelAds",
    path: "/provider/travel-ads",
    icon: <TravelExploreIcon />,
    name: "navigation.myTravelAd",
    active: false,
  },

  // {
  //   id: "batches",
  //   path: "/provider/batches",
  //   icon: <EmojiEventsOutlinedIcon />,
  //   name: "navigation.myBatch",
  // },
  {
    id: "level",
    path: "/provider/level",
    icon: <TrendingUpOutlinedIcon />,
    name: "navigation.myLevel",
    active: false,
  },
  {
    id: "reviews",
    path: "/provider/review",
    icon: <RateReviewOutlinedIcon />,
    name: "navigation.clientReview",
    active: false,
  },
  {
    id: "support",
    path: "/provider/support",
    icon: <HeadsetMicOutlinedIcon />,
    name: "navigation.support",
    active: false,
  },
];
const initialState = {
  sideBarId: "profile",
};
const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarId: (state, action) => {
      state.sideBarId = action.payload;
    },
  },
});
export const { setSidebarId } = sidebarSlice.actions;
export default sidebarSlice.reducer;
