import React, { lazy, Suspense } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChangePassword from "../components/ChangePassword";
import EditAddress from "../components/EditAddress";
import Loading from "../../../../../components/general/Loading";
import { useTranslation } from "react-i18next";
import EditDemographics from "../components/EditDemographics";
import ViewPayment from "../components/ViewPayment";
import EditPersonaliation from "../components/EditPersonalization";
const EditService = lazy(() => import("../components/EditService"));

export default function ProfileSetting({
  setProfile,
  profile,
  providerSignOut,
  isProfileLoading,
}) {
  const { t } = useTranslation();

  const logout = async () => {
    await providerSignOut();
  };

  if (isProfileLoading) return <Loading />;

  return (
    <React.Fragment>
      <h4 className="provider__profilesetting__section__title">
        {t("providers.myProfile.profileSetting")}
      </h4>
      <section className="provider__profilesetting__section__box">
        <ChangePassword title={"change Password"} />
        <EditAddress provider={profile?.provider} setProfile={setProfile} />
        <EditDemographics profile={profile} setProfile={setProfile} />
        <EditPersonaliation profile={profile} setProfile={setProfile} />
        <Suspense fallback={<Loading />}>
          <EditService
            setProfile={setProfile}
            profile={profile}
            isProfileLoading={isProfileLoading}
          />
        </Suspense>
        <ViewPayment />
        <main>
          <section className="provider__profilesetting__section__box__container">
            <h4 className="provider__profilesetting__section__box__container__title">
              <a
                href="https://www.loom.com/share/fd13ead3606b4d8e9e3d9f8f0759f784"
                target="_blank"
              >
                {t("providers.myProfile.accountCreationDemoVideo")}
              </a>
            </h4>
            <div className="provider__profilesetting__section__box__container__icon">
              <ArrowForwardIosIcon fontSize="small" />
            </div>
          </section>
        </main>

        {/* <TermsConditons /> */}
        <section className="provider__profilesetting__section__box__container">
          <h4
            className="provider__profilesetting__section__box__container__logouttitle"
            onClick={logout}
          >
            {t("providers.myProfile.logout")}
          </h4>
          <ArrowForwardIosIcon
            fontSize="small"
            className="provider__profilesetting__section__box__container__icon"
          />
        </section>
      </section>
    </React.Fragment>
  );
}
