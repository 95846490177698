import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

function capitalizeName(name) {
  return name
    .toLowerCase() // Ensure the rest of the letters are in lowercase
    .split(" ") // Split the name into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
}

export default function BasicInfo({
  title,
  adServices,
  fullName,
  provider_slug,
}) {
  const navigate = useNavigate();
  const [services, setServices] = useState(null);

  useEffect(() => {
    adServices &&
      setServices(adServices.map((service) => service?.name).join(", "));
  }, [adServices]);

  return (
    <Box className="clientAdPorfile__basicInfo">
      <h1 className="clientAdPorfile__basicInfo__services">
        {title.toUpperCase()}{" "}
        <span
          className="clientAdPorfile__basicInfo__services__fullName"
          onClick={() => navigate(`/provider/${provider_slug}/portfolio`)}
        >
          {" "}
          By {capitalizeName(fullName)}
        </span>
      </h1>
      <p style={{ fontSize: "14px", fontFamily: "Roboto" }}>{services}</p>
    </Box>
  );
}
