import React, { useEffect, useState } from "react";
import ClientPage from "../../../../components/PageWrapper/ClientPage";
import { useParams } from "react-router-dom";
import ProviderAds from "../../../../apis/client/ProviderAds";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BodyWerxLoading from "../../../../components/general/BodyWerxLoading";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Button,
} from "@mui/material";
import AdDescription from "./components/AdDescription";
import AdPhotos from "./components/AdPhotos";
import BasicInfo from "./components/BasicInfo";

export default function AdProfile() {
  const { t } = useTranslation();
  const { ad_slug, provider_slug, travel_ad } = useParams();
  const navigate = useNavigate();
  const [ad, setAd] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { isLoading, providerAd } = ProviderAds();

  const fetchAd = async () => {
    const response = await providerAd(provider_slug, ad_slug, travel_ad);
    if (response.data) {
      setAd(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchAd();
  }, []);

  if (isLoading) return <BodyWerxLoading />;
  if (errorMessage) return null;
  console.log(ad);
  return (
    <ClientPage>
      {ad ?
        <Box className="clientAdPorfile">
          <BasicInfo
            title={ad.title}
            adServices={ad.services}
            fullName={ad.provider.full_name}
            provider_slug={provider_slug}
          />
          <Grid
            className="clientAdPorfile__mainContainer"
            container
            spacing={3}
          >
            <Grid
              className="clientAdPorfile__mainContainer__adDetails"
              item
              xs={12}
              md={7}
            >
              <AdDescription ad={ad} provider_slug={provider_slug} />
            </Grid>
            <Grid
              className="clientAdPorfile__mainContainer__adPhotos"
              container
              item
              xs={12}
              md={5}
              sx={{ display: "flex" }}
              spacing={3}
            >
              <AdPhotos
                profileImage={ad.profile_image}
                photos={ad.ad_photos}
                email={ad.provider.email}
                phone={ad.provider.phone}
              />
            </Grid>
          </Grid>
        </Box>
      : <BodyWerxLoading />}
    </ClientPage>
  );
}
