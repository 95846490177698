import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ImagesCard = ({ ad }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { client } = useSelector((state) => state.clientAuth);
  const [disable, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(ad?.ad_photos.length <= 2);
  }, [ad]);

  return (
    <div
      className="image-card"
      onClick={() =>
        navigate(
          `/providers/${ad.provider.slug}/ads/${ad.slug}/${ad.travel_ad}`
        )
      }
    >
      <div className="image-card__container">
        <img
          src={
            ad?.profile_image ||
            require("../../../../../assets/Profile_Image.jpg")
          }
          alt={ad?.title || "Profile"}
          className="image-card__image"
          loading="lazy"
        />
        <div className="image-card__overlay">
          <h3 className="image-card__title">{ad?.title}</h3>
        </div>
      </div>
    </div>
  );
};

export default ImagesCard;
