import React, { useState, useEffect } from "react";
import Search from "./Search";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function LangingPageItem({ ad, flag }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [backgroundColor, setBackgroundColor] = useState("#87C85B");

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const navigateToAds = () => {
    navigate(`/ads/${search}`);
  };

  return (
          <figure className="landingPageCarousel__imagesSection" >
            {flag ? (
              <img
                src={
                  ad.adPhoto ||
                  require("../../../../../assets/Landing_Page_1.png")
                }
                alt={`${ad.screenName} providing services at bodywerx`}
                style={{ height: "310px", width: "100%",objectFit:"cover",borderRadius:"6px" }}
                className="landingPageCarousel__imagesSection__image"
              />
            ) : (
              <img
                src={require("../../../../../assets/Landing_Page_1.png")}
                alt="bodywerx "
                className="landingPageCarousel__imagesSection__image"
              />
            )}
          </figure>
  );
}
