import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Grid } from "@mui/material"; // This import is not used in the component
import CopyEmailButton from "./CopyEmailButton";
import CopyPhoneButton from "./CopyPhoneButton";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { Email as EmailIcon } from "@mui/icons-material";
import { Phone as PhoneIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export default function AdPhotos({ profileImage, photos, email, phone }) {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const { client } = useSelector((state) => state.clientAuth);
  const navigate = useNavigate();

  return (
    <>
      <Grid
        className="clientAdPorfile__mainContainer__adPhotos__container"
        item
        xs={12}
        md={8}
      >
        <div className="clientAdPorfile__mainContainer__adPhotos__container__contactAt">
          <h3>Contact At</h3>
          <Box className="clientAdPorfile__mainContainer__adPhotos__container__contactAt__copyButtons">
            {client == null ?
              <>
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/signin")}
                >
                  <EmailIcon />
                </IconButton>
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/signin")}
                >
                  <PhoneIcon />
                </IconButton>
              </>
            : <>
                <CopyEmailButton email={email} />{" "}
                <CopyPhoneButton phone={phone} />
              </>
            }
          </Box>
        </div>
        <img
          src={
            profileImage || require("../../../../../assets/Profile_Image.jpg")
          }
          alt="Main"
          style={{ width: "100%", borderRadius: "8px" }}
        />
      </Grid>
      <Grid
        className="clientAdPorfile__mainContainer__adPhotos__list"
        item
        xs={12}
        sm={12}
        md={4}
      >
        <Box className="clientAdPorfile__mainContainer__adPhotos__list__container">
          {photos.map((photo) => (
            <img
              key={photo.id}
              src={photo.image_url}
              alt="Thumbnail"
              style={{}}
              onClick={() => setSelectedPhoto(photo.image_url)}
            />
          ))}
        </Box>
      </Grid>
      <Dialog
        open={Boolean(selectedPhoto)}
        onClose={() => setSelectedPhoto(null)}
        maxWidth="md"
      >
        <DialogContent style={{ position: "relative", padding: 0 }}>
          <IconButton
            onClick={() => setSelectedPhoto(null)}
            style={{
              position: "absolute",
              top: "8px",
              right: "8px",
              background: "rgba(0, 0, 0, 0.5)",
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedPhoto}
            alt="Preview"
            style={{
              display: "block",
              maxWidth: "100%",
              maxHeight: "90vh",
              margin: "auto",
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
