import React, { useState, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Rating } from "@mui/material";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
export default function PersonalInformation({ ad }) {
  const { t } = useTranslation();

  return (
    <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer">
      <Typography
        variant="h4"
        className="clientAdPorfile__mainContainer__adDetails__informationContainer__heading"
        sx={{ width: "250px" }}
      >
        {t("client.adsProfile.personalInformation")}
      </Typography>
      <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer__content">
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.experience")} :{" "}
          <span className="font__style">
            {ad.provider_detail.experience || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.smokingFrequency")} :{" "}
          <span className="font__style">
            {ad.provider_detail.smoking_frequency || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.drugFrequancy")} :{" "}
          <span className="font__style">
            {ad.provider_detail.drug_frequency || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.sexualOrientation")} :{" "}
          <span className="font__style">
            {ad.provider_detail.sexual_orientation || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.ethnicity")} :{" "}
          <span className="font__style">
            {ad.provider_detail.ethnicity || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.language")} :{" "}
          <span className="font__style">
            {ad.provider_detail.languages || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.workLocation")} :{" "}
          <span className="font__style">
            {ad.provider_detail.work_locations || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.workSurface")}:{" "}
          <span className="font__style">
            {" "}
            {ad.provider_detail.work_surfaces || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.weigth")} :{" "}
          <span className="font__style">
            {ad.provider_detail.weight || "No Set"}{" "}
            {ad.provider_detail.weight && ad.provider_detail.weight_unit}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.heigth")} :{" "}
          <span className="font__style">
            {" "}
            {ad.provider_detail.height || "No Set"}{" "}
            {ad.provider_detail.height && ad.provider_detail.height_unit}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.hairColor")} :{" "}
          <span className="font__style">
            {" "}
            {ad.provider_detail.hair_color || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.eyeColor")} :{" "}
          <span className="font__style">
            {" "}
            {ad.provider_detail.eye_color || "No Set"}
          </span>
        </h4>
        <h4 className="adsProfile__profileData__Information">
          {t("client.adsProfile.bodyHair")} :{" "}
          <span className="font__style">
            {ad.provider_detail.body_hair || "No Set"}
          </span>
        </h4>
      </Box>
    </Box>
  );
}
