import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import LangingPageItem from "./LangingPageItem";
import { Paper } from "@mui/material";

function LandingPageCarousel({ ads }) {
  const [providerAds, setProviderAds] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const breakpoints = {
    500: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    800: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 4,
    },
    1400: {
      slidesPerView: 5,
    },
    1600: {
      slidesPerView: 6,
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Ensure we only display full cards
  // const slidesPerView = Math.max(1, Math.floor(screenWidth / cardWidth));

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSlidesPerView = () => {
    return Object.keys(breakpoints)
      .sort((a, b) => b - a)
      .find((breakpoint) => screenWidth >= breakpoint);
  };

  const slidesPerView = breakpoints[getSlidesPerView()]?.slidesPerView || 1;

  useEffect(() => {
    ads.map((ad) => {
      if (ad.ad_photos.length > 0) {
        setProviderAds((prevProviderAds) => [
          ...prevProviderAds,
          {
            screenName: ad.provider.screen_name,
            adPhoto: ad.ad_photos[0]?.image_url,
            title: ad.title,
            services: ad.services.map((service) => service.name).join(", "),
          },
        ]);
      }
    });
  }, [ads]);

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={20}
      slidesPerView={slidesPerView}
      pagination={{ clickable: true }}
      className="landingPageCarousel"

      // autoplay={{
      //   delay: 5000,
      //   disableOnInteraction: false,
      // }}
    >
      {providerAds.length > 0 ? (
        providerAds.map((providerAd, index) => (
          <SwiperSlide key={index}>
            <LangingPageItem key={providerAd.id} ad={providerAd} flag={true} />
          </SwiperSlide>
        ))
      ) : (
        <SwiperSlide>
          <LangingPageItem flag={false} />
        </SwiperSlide>
      )}

      {/* <section className="landingPageCarousel2">
        <section className="landingPageCarousel2__search">
          <Search handleChange={handleChange} fetchAds={navigateToAds} />
        </section>
        <section className="landingPageCarousel2__body">
          <div className="landingPageCarousel2__body__left">
            <figure className="landingPageCarousel2__body__left__image">
              <img src={require("../../../../../assets/logo.jpeg")} alt="" />
            </figure>
            <aside className="landingPageCarousel2__body__left__text">
              <p className="landingPageCarousel2__body__left__text__text1">
                Since 2015 BodyWerx has been providing a directory for
                professional men offering and promoting their services to
                potential clients worldwide.
              </p>
              <p className="landingPageCarousel2__body__left__text__text2">
                Search through BodyWerx to find the man that needs your specific
                need at the time you need it...
              </p>
            </aside>
          </div>
          <section className="landingPageCarousel2__body__right">
            <figure className="landingPageCarousel2__body__right__image1">
              <img
                src={require("../../../../../assets/Landing_Page_2.png")}
                alt=""
              />
            </figure>
          </section>
        </section>
        <p className="landingPageCarousel2__text2">
          Search through BodyWerx to find the man that needs your specific need
          at the time you need it...
        </p>
      </section>
      <section className="landingPageCarousel3">
        <section className="landingPageCarousel3__search">
          <Search handleChange={handleChange} fetchAds={navigateToAds} />
        </section>
        <section className="landingPageCarousel3__body">
          <div className="landingPageCarousel3__body__left">
            <figure className="landingPageCarousel3__body__left__image">
              <img src={require("../../../../../assets/logo.jpeg")} alt="" />
            </figure>
            <aside className="landingPageCarousel3__body__left__text">
              <p className="landingPageCarousel3__body__left__text__text1">
                Since 2015 BodyWerx has been providing a directory for
                professional men offering and promoting their services to
                potential clients worldwide.
              </p>
              <p className="landingPageCarousel3__body__left__text__text2">
                Search through BodyWerx to find the man that needs your specific
                need at the time you need it...
              </p>
            </aside>
          </div>
          <section className="landingPageCarousel3__body__right">
            <figure className="landingPageCarousel3__body__right__image1">
              <img
                src={require("../../../../../assets/Landing_Page_2.png")}
                alt=""
              />
            </figure>
          </section>
        </section>
        <p className="landingPageCarousel3__text2">
          Search through BodyWerx to find the man that needs your specific need
          at the time you need it...
        </p>
      </section> */}
    </Swiper>
  );
}

export default LandingPageCarousel;
