import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Box } from "@mui/material";
import process from "process";

export default function Location({ lat, long }) {
  const { t } = useTranslation();
  return (
    <>
      <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer">
        <Typography
          variant="h4"
          className="clientAdPorfile__mainContainer__adDetails__informationContainer__heading"
          sx={{ width: "120px" }}
        >
          {t("client.adsProfile.loc")}
        </Typography>
        <Grid className="clientAdPorfile__mainContainer__adDetails__informationContainer__table">
          <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer__table__location">
            <iframe
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDMyLBbW93pSSmN85s6hrVOaxIvcTa4jOA&q=${lat},${long}&zoom=14`}
            />
          </Box>
        </Grid>
      </Box>
    </>
  );
}
