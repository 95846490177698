import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PersonalInformation from "./PersonalInformation";
import Rate from "./Rate";
import ServiceHour from "./ServiceHour";
import Location from "./Location";
import Review from "./Review";
export default function AdDescription({ ad, provider_slug }) {
  const { t } = useTranslation();
  return (
    <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer">
      <Typography
        variant="h4"
        className="clientAdPorfile__mainContainer__adDetails__informationContainer__heading"
        sx={{ width: "120px" }}
      >
        {t("client.adsProfile.overview")}
      </Typography>
      <Box className="clientAdPorfile__mainContainer__adDetails__informationContainer__content">
        <Typography
          variant="body1"
          className="clientAdPorfile__mainContainer__adDetails__informationContainer__description"
        >
          {ad.provider.about}
        </Typography>
        <Typography
          variant="body1"
          className="clientAdPorfile__mainContainer__adDetails__informationContainer__description"
        >
          {ad.about_my_services}
        </Typography>
      </Box>
      <Location lat={ad.provider.latitude} long={ad.provider.longitude} />
      <PersonalInformation ad={ad} />
      <Rate services={ad.services} rates={ad.rates} />
      <ServiceHour serviceHours={ad.service_hours} />
      <Review provider_slug={provider_slug} />
    </Box>
  );
}
