import React, { useState, useEffect } from "react";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AdCard from "../../../../components/ProviderAd/AdCard";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import AdsApis from "../../../../apis/provider/AdsApis";
import Loading from "../../../../components/general/Loading";
import { useTranslation } from "react-i18next";
import { Alert, Box } from "@mui/material";

const ProviderAds = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [adsData, setAdsData] = useState({
    ads: [],
    rates: 0,
    service_hours: 0,
    photos: 0,
  });
  const { getAds, isAdLoading } = AdsApis();
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchAd = async () => {
    const response = await getAds();
    if (response.data) {
      setAdsData(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };
  useEffect(() => {
    fetchAd();
  }, []);
  console.log(adsData);
  if (errorMessage) return null;
  debugger;
  return (
    <main className="myAds">
      {isAdLoading ?
        <div className="circularLoading">
          <Loading />
        </div>
      : <section className="myAds__body">
          <header>
            <h2>{t("providers.myAd.adHeader")}</h2>

            {adsData.rates > 0 &&
              adsData.service_hours > 0 &&
              adsData.photos > 0 && (
                <div className="myAds__body__container__adsContainer__addNewButton">
                  <Button
                    variant="contained"
                    className="newAdModalButton"
                    onClick={() => navigate("/provider/newAd")}
                  >
                    {t("providers.myAd.newAd")}
                  </Button>
                </div>
              )}
          </header>

          {(adsData.rates == 0 ||
            adsData.service_hours == 0 ||
            adsData.photos == 0) && (
            <Box sx={{ marginTop: "10px" }}>
              <Alert severity="warning">
                {t("providers.myAd.ratesAndServiceHourNotUploaded")}
              </Alert>
            </Box>
          )}
          <section className="myAds__body__container2">
            {adsData.ads.map((ad) => (
              <div className="myAds__body__container2__adsContainer2">
                <AdCard ad={ad} />
              </div>
            ))}
          </section>
          {adsData.ads.length == 0 && (
            <div className="myAds__body__container">
              <div className="myAds__body__container__adsContainer">
                <MessageOutlinedIcon className="myAds__body__container__adsContainer__adIcon" />
                <p>{t("providers.myAd.noAdd")}</p>
              </div>
            </div>
          )}
        </section>
      }
    </main>
  );
};

export default ProviderAds;
