import React, { useState } from "react";
import PropTypes from "prop-types";
import About from "./About";
import Service from "./Service";
import { styled } from "@mui/material/styles";
import { Tabs, Tab, Typography, Box } from "@mui/material";

const CustomizedTabs = styled(Tabs)`
  .css-heg063-MuiTabs-flexContainer {
    display: flex !important;
    justify-content: space-between !important;
  }
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProviderTabs({ profile }) {
  const [value, setValue] = React.useState(0);
  const [style, setStyle] = useState({
    About: {
      backgroundColor: "#7fc550",
      color: "white",
      borderRadius: "50px",
    },
    Services: {
      backgroundColor: "",
      color: "",
      borderRadius: "",
      width: "",
    },
    Reviews: {
      backgroundColor: "",
      color: "",
      borderRadius: "",
      width: "",
    },
    portfolio: { backgroundColor: "", color: "", borderRadius: "", width: "" },
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = (type) => {
    if (
      type === "About" ||
      type === "Services" ||
      type === "Reviews" ||
      type === "portfolio"
    ) {
      setStyle((prevState) => ({
        prevState,
        [type]: {
          backgroundColor: "#7fc550",
          color: "white",
          borderRadius: "50px",
        },
      }));
    }
  };

  return (
    <Box className="tab__mainconatiner">
      <Box className="tab__mainconatiner__box">
        <CustomizedTabs
          value={value}
          onChange={handleChange}
          indicatorColor="transparent"
          variant="scrollable"
        >
          <Tab
            label="About"
            {...a11yProps(0)}
            style={{
              ...style.About,
            }}
            onClick={() => handleSubmit("About")}
            className="about__tab"
          />
          <Tab
            label="Services"
            {...a11yProps(1)}
            style={{
              ...style.Services,
            }}
            onClick={() => handleSubmit("Services")}
            className="service__tab"
          />
          {/* <Tab
            label="Reviews"
            {...a11yProps(2)}
            style={{
              ...style.Reviews,
            }}
            onClick={() => handleSubmit("Reviews")}
            className="review__tab"
          /> */}
        </CustomizedTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <About profile={profile.provider} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Service ads={profile.ads} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <Reviews provider_slug={profile.provider.slug} />
      </TabPanel> */}
    </Box>
  );
}
