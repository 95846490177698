import React, { useState, useEffect } from "react";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import AdCard from "../../../../components/ProviderAd/AdCard";
import { Tooltip, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TravelAdsApis from "../../../../apis/provider/TravelAdsApis";
import Loading from "../../../../components/general/Loading";
import { useTranslation } from "react-i18next";
import { Alert, Box } from "@mui/material";

export default function TravelAd() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [travelAds, setTravelAds] = useState({
    travel_ads: [],
    rates: 0,
    service_hours: 0,
  });
  const { getTravelAds, isTravelAdLoading } = TravelAdsApis();
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchTravelAd = async () => {
    const response = await getTravelAds();
    if (response.data) {
      setTravelAds(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchTravelAd();
  }, []);

  if (errorMessage) return null;

  return (
    <main className="myAds">
      {isTravelAdLoading ?
        <div className="circularLoading">
          <Loading />
        </div>
      : <section className="myAds__body">
          <header>
            <h2>{t("providers.myTravelAd.adHeader")}</h2>
            <div className="myAds__body__container__adsContainer__addNewButton">
              {travelAds.rates > 0 &&
                travelAds.service_hours > 0 &&
                travelAds.photos > 0 && (
                  <Button
                    variant="contained"
                    className="newAdModalButton"
                    onClick={() => navigate("/provider/travel-ads/new")}
                  >
                    {t("providers.myTravelAd.travelAd")}
                  </Button>
                )}
            </div>
          </header>
          {(travelAds.rates == 0 ||
            travelAds.service_hours == 0 ||
            travelAds.photos == 0) && (
            <Box sx={{ marginTop: "10px" }}>
              <Alert severity="warning">
                {t("providers.myAd.ratesAndServiceHourNotUploaded")}
              </Alert>
            </Box>
          )}
          <section className="myAds__body__container2">
            {travelAds.travel_ads.map((ad) => (
              <div className="myAds__body__container2__adsContainer2">
                <AdCard ad={ad} travelAd={true} />
              </div>
            ))}
          </section>
          {travelAds.travel_ads.length == 0 && (
            <div className="myAds__body__container">
              <div className="myAds__body__container__adsContainer">
                <MessageOutlinedIcon className="myAds__body__container__adsContainer__adIcon" />
                <p>{t("providers.myAd.noAdd")}</p>
              </div>
            </div>
          )}
        </section>
      }
    </main>
  );
}
